"use strict";

function toggle(control) {
    const elem = document.getElementById(control);
    const link = document.getElementById('link_' + control);
    if (elem.style.display === "none") {
        elem.style.display = "block";
        link.innerHTML = "<span class='glyphicon glyphicon-menu-up' aria-hidden='true'/>";
    }
    else {
        elem.style.display = "none";
        link.innerHTML = "<span class='glyphicon glyphicon-menu-down' aria-hidden='true'/>";
    }
}

function toogleAnchor() {
    if (window.location.hash) {
        toggle(window.location.hash.replace("#link_", ""));
    }
}

function previewFile(elementId) {
    const preview = document.getElementById(elementId);
    const file = document.querySelector('input[type=file]').files[0];
    const reader = new FileReader();
    reader.addEventListener("load", function () {
        preview.src = reader.result;
    }, false);
    if (file) {
        reader.readAsDataURL(file);
    }
}

window.previewFile = previewFile;

function setClearfixes() {
    $("#images li .clearfix").remove();
    let inserted = 0;
    for (let i = 0; i < $('#images li').length; i++) {

        if (((i - inserted) % 2 === 0)) {
            $('#images li:eq(' + i + ')').after('<li class="clearfix visible-sm-block"></li>');
            inserted++;
        }
        if (((i - inserted) % 4 === 0)) {
            $('#images li:eq(' + i + ')').after('<li class="clearfix visible-md-block "></li>');
            inserted++;
        }

        if (((i - inserted) % 4 === 0)) {
            $('#images li:eq(' + i + ')').after('<li class="clearfix visible-lg-block"></li>');
            inserted++;
        }

    }
}

$(document).ready(function () {
    $('li .thumbnail img').on('click', function () {
        const src = $(this).attr('src');
        const img = '<img src="' + src + '" class=" center-block img-responsive"/>';
        const moult = $(this).parent().find('.caption .moult').html();
        const date = $(this).parent().find('.caption .date').html();

        const modal = $('#myModal');
        modal.modal();
        modal.on('shown.bs.modal', function () {
            $('#myModal .modal-body #img').html(img);
            $('#myModal .modal-body #caption #modal_moult').html('');
            $('#myModal .modal-body #caption #modal_date').html('');
            $('#myModal .modal-body #caption #modal_moult').html(moult);
            $('#myModal .modal-body #caption #modal_date').html(date);
        });
        modal.on('hidden.bs.modal', function () {
            $('#myModal .modal-body #img').html('');
            $('#myModal .modal-body #caption #modal_moult').html('');
            $('#myModal .modal-body #caption #modal_date').html('');
        });
    });

    document.querySelector('#fileSelect').addEventListener('click', function (e) {
        var fileInput = document.querySelector('#file');
        //click(fileInput); // Simulate the click with a custom event.
        fileInput.click(); // Or, use the native click() of the file input.
    }, false);

    $('[data-toggle="tooltip"]').tooltip();

    $('[data-target="ajax_set_visible"]').submit(function (e) {

        var form = this;

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            dataType: "json",
            data: $(this).serialize(), // serializes the form's elements.
            success: function (data) {

                var input = $(form).find("input[name='visible']");

                input.attr('value', !data);

                if (data) {
                    $(form).find("[data-visible='false']").show();
                    $(form).find("[data-visible='true']").hide();
                } else {
                    $(form).find("[data-visible='false']").hide();
                    $(form).find("[data-visible='true']").show();

                }
            }
        });


        e.preventDefault(); // avoid to execute the actual submit of the form.

    });

});
