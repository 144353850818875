"use strict";

import 'webpack-jquery-ui/autocomplete';

import Modernizr from 'modernizr';

$(document).ready(function () {
    /*
     * Autocomplete für Browser ohne datalist-Unterstützung
     */
    if (!Modernizr.input.list) { //(!('options' in document.createElement('datalist'))) {

        $('input[list]').each(function () {
            const elem = $(this);
            const list = $(document.getElementById(elem.attr('list')));

            const items = list.children().map(function () {
                return {
                    text: $(this).val(),
                    value: $(this).text(),
                };
            }).get();

            elem.autocomplete({
                source: items,
                minLength: 0,

                focus: function (event, ui) {
                    if (ui.item.text === undefined || ui.item.text === null) {
                        elem.val(ui.item.value);
                    } else {
                        elem.val(ui.item.text);
                    }
                    return false;
                },
                select: function (event, ui) {
                    if (ui.item.text === undefined || ui.item.text === null) {
                        elem.val(ui.item.value);
                    } else {
                        elem.val(ui.item.text);
                    }
                    return false;
                },

            });
        });
    }

});
