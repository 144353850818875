require('./vendor/jquery.serialize-object');

require('./stickynav.js');
require('./genes.js');
require('./genecalc.js');
require('./datepicker.js');
require('./datalist.js');
require('./select2Enable.js');
//require('./snakemeter.js');
require('./images.js');
require('./terracalc.js');
require('./non-js-hidden');
require('./hatchlings');
require('./species');
require('./snake');
require('./snakes');
require('./market');

require('./vendor/bootstrap.min.js');

$('[data-toggle="tooltip"]').tooltip();
