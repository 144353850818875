"use strict";

import Modernizr from 'modernizr';
import 'webpack-jquery-ui/datepicker';
import './vendor/jquery-ui-i18n.min.js';
import 'jquery-ui/themes/base/theme.css';

$(document).ready(function () {
    if (!Modernizr.inputtypes.date) {
        const locale = $("html").attr('lang');
        $.datepicker.setDefaults($.datepicker.regional[locale]);

        $('input[type="date"]').each(function (index, value) {

            if (!$(value).attr("alt-field")) {
                //add alt-date-input to form
                const parent = $(value).parent();

                const input = document.createElement("input");

                const input_id = $(value).id ? $(value).id : guid();

                input.id = 'alt-' + input_id;
                input.type = "hidden";

                parent.append(input);

                $(value).attr("alt-field", 'alt-' + input_id);

            }

            enableWithAlt(value);
        });
    }

});

function guid() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

function enableWithAlt(value) {
    const alt_id = $(value).attr("alt-field");
    $('#' + alt_id).attr("name", $(value).attr("name"));
    $(value).attr("name", "");

    $(value).datepicker({

        altFormat: 'yy-mm-dd',
        altField: '#' + alt_id,

        dateFormat: "dd.mm.yy",
        beforeShow: function () {
            setTimeout(function () {
                $('.ui-datepicker').css('z-index', 3);
            }, 0);
        },
        onClose: function (dateText, inst) {
            if (!dateText) {
                $($('#' + alt_id)).attr("value", "");
            }
        },
    });

    if ($(value).attr('value')) {

        const date = $.datepicker.formatDate("dd.mm.yy", new Date($(value).attr('value')));

        $(value).datepicker("setDate", date);
    }

}
