"use strict";

$(document).ready(function () {

    $("#form_add_species").submit(function (e) {

        var url = $(this).attr('action');
        var method = $(this).attr('method');

        $.ajax({
            type: method,
            url: url,
            data: $(this).serialize(), // serializes the form's elements.
            success: function (data) {

                $('#species').append($('<option>', {
                    value: data.id,
                    text: data.fullName
                }));

                $('#species').val(data.id).change();

                $('#modal_add_species').modal('hide')

            }
        });

        e.preventDefault(); // avoid to execute the actual submit of the form.
    });
})
