"use strict";

import 'webpack-jquery-ui/autocomplete';


export function setUpGenes() {

    const genesElems = $(".genes");//TODO: use data-attributes (like data-genes="cornsnake"?)
    genesElems.each((i, e) => {
        const elem = $(e);
        let genes_url = elem.attr("data-genes-url");
        if (!genes_url) {
            genes_url = '/public/genecalc/cornsnake/names';
        }

        $.getJSON(genes_url, function (data) {
            const availableTags = data;
            set_up_autocomplete(elem, availableTags);
        });


    });

    const set_up_autocomplete = (elem, availableTags) => {

        function split(val) {
            return val.split(/[ ,]+\s*/);
        }

        function extractLast(term) {
            return split(term).pop();
        }

        // don't navigate away from the field on tab when selecting an item
        elem
            .bind("keydown", function (event) {
                if (event.keyCode === $.ui.keyCode.TAB &&
                    $(this).autocomplete("instance").menu.active) {
                    event.preventDefault();
                }
            })
            .autocomplete({
                minLength: 0,
                source: function (request, response) {
                    // delegate back to autocomplete, but extract the last term
                    response($.ui.autocomplete.filter(
                        availableTags, extractLast(request.term)));
                },
                focus: function () {
                    // prevent value inserted on focus
                    return false;
                },
                select: function (event, ui) {
                    const terms = split(this.value);
                    // remove the current input
                    terms.pop();
                    // add the selected item
                    terms.push(ui.item.value);
                    // add placeholder to get the comma-and-space at the end
                    terms.push("");
                    this.value = terms.join(" ");
                    return false;
                }
            });

    }

}

$(document).ready(setUpGenes);
