$(window).scroll(function () {
    var headerHeight = $('#logo').height();
    var contentHeight = $('ns-content').height();

    if ($(this).scrollTop() > $('#logo').height()) {
        $(".mainnav").addClass('sticky');
        $(".wrap").addClass('sticky-wrap');
        $("main").addClass('sticky-wrap');

        if ($(this).scrollTop() > ($('.ns-detail-header-section').height() +  $('#logo').height())) {
            $('.ns-detail-nav').addClass('sticky');
        } else {
            $('.ns-detail-nav').removeClass('sticky');
        }

    } else {
        $(".mainnav").removeClass('sticky');
        $(".wrap").removeClass('sticky-wrap');
        $("main").removeClass('sticky-wrap');

    }

});


