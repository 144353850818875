"use strict";

/**
 * Created by Zukie on 21/01/16.
 */
function toggle(control) {
    const elem = document.getElementById(control);
    const link = document.getElementById('link_' + control);
    if (elem.style.display === "none") {
        elem.style.display = "block";
        link.innerHTML = "<span class='glyphicon glyphicon-menu-up' aria-hidden='true'/>";
    }
    else {
        elem.style.display = "none";
        link.innerHTML = "<span class='glyphicon glyphicon-menu-down' aria-hidden='true'/>";
    }
}

const genotype = 0;
const extended = 1;
let currentGenotype = genotype;

function toogleGenotype() {

    const genotypeElements = document.getElementsByClassName("genotype");
    for (let i = 0; i < genotypeElements.length; i++) {

        if (currentGenotype === genotype) {
            genotypeElements[i].style.display = "none";
        } else {
            genotypeElements[i].style.display = "block";
        }

    }
    const extendedElements = document.getElementsByClassName("extendedGenotype");
    for (let i = 0; i < extendedElements.length; i++) {

        if (currentGenotype === genotype) {
            extendedElements[i].style.display = "block";
        } else {
            extendedElements[i].style.display = "none";
        }
    }

    const button = document.getElementById('toggleGenotypeButton');
    if (currentGenotype === genotype) {
        currentGenotype = extended;
        button.innerHTML = button.getAttribute('data-simple');
    } else {
        currentGenotype = genotype;
        button.innerHTML = button.getAttribute('data-extended');
    }

}

function switchResult(switchTo) {

    const genotypeElements = document.getElementsByClassName("genotype");
    for (let i = 0; i < genotypeElements.length; i++) {
        document.getElementById('genotypeIndex' + i).style.display = "none";
    }

    const curr = document.getElementById('genotypeIndex' + switchTo);

    curr.style.display = "block";
}

function sortGenotypeList(elem, attr, order) {

    const items = elem.querySelectorAll("li");//<li>s


    const itemsArr = [];
    for (let i in items) {
        if (items[i].nodeType === 1) { // get rid of the whitespace text nodes
            itemsArr.push(items[i]);
        }
    }

    itemsArr.sort(function (a, b) {
        const keyA = parseInt(a.getAttribute(attr));
        const keyB = parseInt(b.getAttribute(attr));

        if (keyA < keyB) return -order;
        if (keyA > keyB) return order;
        return 0;
    });

    for (let i = 0; i < itemsArr.length; ++i) {
        elem.appendChild(itemsArr[i]);
    }
}


$(document).ready(function () {

    $('[data-toogle="phet"]').click(function () {
            switchResult(this.getAttribute('data-target'));
        }
    );

    $('[data-toogle="sort-genotypes"]').click(function () {

            $('[data-toogle="sort-genotypes"]').removeClass('active');


            const order = this.getAttribute('data-order');
            const attr = this.getAttribute('data-target');


            $('[data-toogle="sort-genotypes"][data-order="' + order + '"][data-target="' + attr + '"]').addClass('active');

            const uls = $('[genotype-list="genotype-list"]');
            $.each(uls, function (i, elem) {
                sortGenotypeList(elem, attr, order);
            });
        }
    );

    $('[data-toggle="tooltip"]').tooltip();

    $('#with_same_male').click(function () {
        $('#male').val([]).change();
        setFemale($('#genotype_male').val());
    });
    $('#with_same_select_male').click(function () {
        $('#genotype_male').val("");
        setFemale($('#male option:selected').attr('data-genes'));
    });

    $('#with_same_female').click(function () {
        $('#female').val([]).change();
        setMale($('#genotype_female').val());
    });
    $('#with_same_select_female').click(function () {
        $('#genotype_female').val("");
        setMale($('#female option:selected').attr('data-genes'));
    });

    function setFemale(genes) {
        $('#female').val([]).change();
        $('#genotype_female').val(genes);
        $('#genotype_female').focus();
        $('#genecalc_form').submit();

    }

    function setMale(genes) {
        $('#male').val([]).change();
        $('#genotype_male').val(genes);
        $('#genotype_male').focus();
        $('#genecalc_form').submit();

    }

});

window.toggle = toggle;
window.toogleGenotype = toogleGenotype;
window.switchResult = switchResult;
window.sortGenotypeList = sortGenotypeList;
