"use strict";

require("./vendor/select2.js");

$(document).ready(function () {
    const elem = $(".select2");
    if (elem.select2) {
        elem.select2();
    }
});
