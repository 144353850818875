"use strict";


$(document).ready(function () {
    const anchor = (window.location.hash) ? window.location.hash : $("head").attr('data-anchor');
    if (anchor) {
        var tab = anchor.replace("#", "");
        $("#" + tab + "-toogle").tab('show');
    }

    $("#preMoult").change(function () {

        $.ajax({
            type: this.form.method,
            url: this.form.action,
            dataType: "json",
            data: $(this.form).serialize(),
            success: function (data) {
                //this.prop('checked', data);
            },
            error: function (data) {
                //this.prop('checked', data);
            },
            complete: function (data) {
                //this.prop('checked', data);
            }

        });

    });

    $("#inHibernation").change(function () {

        $.ajax({
            type: this.form.method,
            url: this.form.action,
            dataType: "json",
            data: $(this.form).serialize(),
            success: function (data) {
                //this.prop('checked', data);
            },
            error: function (data) {
                //this.prop('checked', data);
            },
            complete: function (data) {
                //this.prop('checked', data);
            }

        });

    });

    $("#public_visible").change(function () {

        $.ajax({
            type: this.form.method,
            url: this.form.action,
            dataType: "json",
            data: $(this.form).serialize(),
            success: function (data) {
                //this.prop('checked', data);
            },
            error: function (data) {
                //this.prop('checked', data);
            },
            complete: function (data) {
                //this.prop('checked', data);
            }

        });

    });


});
