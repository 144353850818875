"use strict";

import {setUpGenes} from "./genes";

$(document).ready(function () {

    $('[data-toggle="tooltip"]').tooltip();

    $("#CLUTCH_NR").hide();

    $(".tags").on("select2:select", function (evt) {
        var element = evt.params.data.element;
        var $element = $(element);

        if (element.value === "CLUTCH_NR") {
            $("#CLUTCH_NR").show();
        }

        $element.detach();
        $(this).append($element);
        $(this).trigger("change");
    });


    $(".tags").on("select2:unselect", function (evt) {
        var element = evt.params.data.element;

        if (element.value === "CLUTCH_NR") {
            $("#CLUTCH_NR").hide();
        }
    });

    $("#clutchNr, #delemiter, #nameGeneratorTypes, #name").change(function (e) {
        generateName();
    });

    $("#generatedName").hide();
    $("#generatedNameInfo").show();

});


function generateName() {

    let types = [];
    types = $("#nameGeneratorTypes").val();
    if (types == null) {
        types = [];
    }

    let url = $("#name_generator").attr("data-href");

    $.get(url,
        {
            name: $("#name").val(),
            clutchNr: $("#clutchNr").val(),
            delemiter: $("#delemiter").prop('checked'),
            nameGeneratorTypes: JSON.stringify(types).replace(/\"/g, "").replace(/\[/g, "").replace(/\]/g, ""),
            index: 1
        },
        function (data) {
            if (data !== "") {
                $("#generatedName").show();
                $("#generatedNameInfo").hide();
                $("#generatedName>span").text(data);
            } else {
                $("#generatedName").hide();
                $("#generatedNameInfo").show();
            }
        });

}

function addGenotypes() {
    let nr = $("#addGenotypesBtn").attr("next");


    let node = $("#genotypes-form:hidden").clone();
    node.attr("id",null);
    $(node).find("input").each(function (i, e) {
        $(e).attr("name", ($(e).attr("name").replace("${nr}", nr)));
    });

    node.show();

    $("#genotypes").append(node);
    nr++;
    $("#addGenotypesBtn").attr("next", nr);
    setUpGenes();
}


window.generateName = generateName;
window.addGenotypes = addGenotypes;
