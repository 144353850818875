"use strict";


var sideBarWidth;

var next_food = "";

$(document).ready(function () {
    $('.submit_on_change').change(function (e) {
        this.form.submit();
    });
});

$(document).ready(function () {

    const url = $("#next_feedings_toogle").attr('data-href');

//            $.get(url, function (data) {
//                next_food = data;
//                $("#modal_show_needed_food .modal-body > ul").replaceWith(next_food);
//                $("#modal_show_needed_food #loading").hide();
//            });


    $("#calc_next_feeding").submit(function (e) {

        $('#modal_show_needed_food').modal('show');
        $("#modal_show_needed_food .modal-body > ul").replaceWith("<ul></ul>");
        $("#modal_show_needed_food #loading").show();

        var url = $(this).attr('action');
        var method = $(this).attr('method');

        $.ajax({
            type: method,
            url: url,
            dataType: "html",
            data: $(this).serialize(), // serializes the form's elements.
            success: function (data) {

                next_food = data;
                $("#modal_show_needed_food .modal-body > ul").replaceWith(next_food);
                $("#modal_show_needed_food #loading").hide();
            }
        });

        e.preventDefault(); // avoid to execute the actual submit of the form.
    });

    $(".ajax-feeding-form").submit(function (e) {

        const form = this;

        $.ajax({
            type: this.method,
            url: this.action,
            dataType: "json",
            contentType: "application/json",
            data: $(this).serializeJSON(),
            success: function () {
                $(form).find('button span').toggleClass($(form).find('button span').attr("class-success"));
                $(form).find('button').removeClass('btn-primary').addClass('btn-success');
            },
            error: function () {
                $(form).find('button span').toggleClass($(form).find('button span').attr("class-error"));
                $(form).find('button').removeClass('btn-primary').addClass('btn-danger');
            },
            complete: function () {
                $(form).attr("action", "#");
                $(form).find('button').attr("disabled", true);
            }

        });
        e.preventDefault();

    });

    $("#ajax-next-feeding-form select").change(function (e) {

        const form = this.form;

        $.ajax({
            type: form.method,
            url: form.action,
            dataType: "html",
            data: $(form).serialize(),
            success: function (data) {
                $(".needed_food_body").replaceWith(data);
            },
            error: function () {

            },
            complete: function (data) {
            }

        });
        e.preventDefault();

    });

});


$(document).ready(function () {

    const sidebar = $('#sidebar');
    if (!(sidebar && sidebar.length > 0)) {
        return;
    }
    sideBarWidth = sidebar.width();

    sidebar.affix({
        offset: {
            top: sidebar.offset().top - 20
        }
    });

    $(window).resize(function () {
        $('#sidebar').width(sideBarWidth);
    });

    $(window).scroll(function () {
        $('#sidebar').width(sideBarWidth);
    });
});




$(document).ready(function () {
    $('#search,#search-xs').on('input change', function (e) {
        const name = $('#search').val().toLowerCase() + $('#search-xs').val().toLowerCase().trim();

        const tokens = name.split(/[ ,]+/);

        $("li.snake").each(function (index, element) {
            var visible = search(element, name, tokens);

            if (visible || name === "") {
                $(element).show();
            } else {
                $(element).hide();
            }

        });


    });

    $('#search,#search-xs').on('submit', function (e) {
        e.preventDefault();
    });
});

function search(snake_elem, query, tokens) {
    let visible = false;
    $(snake_elem).find(".snake-name,.snake-genes,.snake-species").each(function (i, elem) {
        visible = visible || contains_all_tokens($(elem).text(), tokens);
    });
    return visible;

}

function contains_all_tokens(string, tokens) {
    let visible = true;
    $.each(tokens, function (i, token) {
        visible = visible && (string.toLowerCase().indexOf(token) >= 0);
    });
    return visible;
}
