"use strict";

$(document).ready(function () {

    $('.checkbox-dropdown a').on('click', function (event) {
        var $target = $(event.currentTarget),
            val = $target.attr('data-value'),
            $inp = $target.find('input'),
            idx;

        if ($inp.prop('checked')) {
            setTimeout(function () {
                $inp.prop('checked', false)
            }, 0);
        } else {
            setTimeout(function () {
                $inp.prop('checked', true)
            }, 0);
        }

        $(event.target).blur();

        return false;
    });

});
