"use strict";

/**
 * Created by yannick-broeker on 18.05.16.
 */
window.terracalc = {
    calc: function () {
        const maxDepthElem = document.getElementById('maxDepth');
        const snakeLengthElem = document.getElementById('snakeLength');
        const snakesElem = document.getElementById('snakes');

        if (!(maxDepthElem && snakeLengthElem && snakesElem)) {
            return;
        }

        const maxDepth = maxDepthElem.value;
        const snakeLength = snakeLengthElem.value;

        let snakes = snakesElem.value;

        let height = snakeLength * 1.0;
        let depth = maxDepth * 1.0;
        if (depth > 0.5 * snakeLength) {
            depth = 0.5 * snakeLength;
        }

        let length = (snakeLength * (0.5 * snakeLength)) / depth;

        while (snakes > 2) {
            snakes--;
            length = length * 1.1;
            height = height * 1.1;
        }


        document.getElementById('length').innerHTML = Number(length.toFixed(2));
        document.getElementById('height').innerHTML = Number(height.toFixed(2));
        document.getElementById('depth').innerHTML = Number(depth.toFixed(2));

        document.getElementById('terra_length').value = Number((length * 100).toFixed(0));
        document.getElementById('terra_height').value = Number((height * 100).toFixed(0));
        document.getElementById('terra_depth').value = Number((depth * 100).toFixed(0));
        terracalc.calc_terra();
    },

    calc_terra: function () {
        if (!document.getElementById('terra_length')) {
            return;
        }

        /**
         * Breite.
         */
        const length = parseInt(document.getElementById('terra_length').value) * 10;
        /**
         * Höhe
         */
        const height = parseInt(document.getElementById('terra_height').value) * 10;
        /**
         * Tiefe
         */
        const depth = parseInt(document.getElementById('terra_depth').value) * 10;

        const front_upper = parseInt(document.getElementById('front_upper').value) * 10;

        const front_lower = parseInt(document.getElementById('front_lower').value) * 10;

        /**
         * Dicke Holz
         */
        const thick = parseInt(document.getElementById('thick').value);


        const top_length = length;
        const top_depth = depth;


        document.getElementById('top_length').innerHTML = Number(top_length);
        document.getElementById('top_depth').innerHTML = Number(top_depth);


        const bottom_length = length - 2 * thick;
        const bottom_depth = depth - 2 * thick;

        document.getElementById('bottom_length').innerHTML = Number(bottom_length);
        document.getElementById('bottom_depth').innerHTML = Number(bottom_depth);

        const side_length = height - thick;
        const side_depth = depth;

        document.getElementById('side_length').innerHTML = Number(side_length);
        document.getElementById('side_depth').innerHTML = Number(side_depth);

        const back_length = height - thick;
        const back_depth = length - 2 * thick;

        document.getElementById('back_length').innerHTML = Number(back_length);
        document.getElementById('back_depth').innerHTML = Number(back_depth);

        const front_upper_length = length - 2 * thick;
        const front_upper_depth = front_upper;

        document.getElementById('front_upper_length').innerHTML = Number(front_upper_length);
        document.getElementById('front_upper_depth').innerHTML = Number(front_upper_depth);

        const front_lower_length = length - 2 * thick;
        const front_lower_depth = front_lower;

        document.getElementById('front_lower_length').innerHTML = Number(front_lower_length);
        document.getElementById('front_lower_depth').innerHTML = Number(front_lower_depth);

    }
};

$(document).ready(function () {
    terracalc.calc();
    terracalc.calc_terra();
});
